import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-preisfindung',
  templateUrl: './preisfindung.component.html',
  styleUrls: ['./preisfindung.component.scss']
})
export class PreisfindungComponent implements OnInit {

  constructor(private titleService:Title) {
    this.titleService.setTitle("Preisfindung");
   }

  ngOnInit(): void {
  }

}
