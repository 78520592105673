import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { GruendenMit40Component } from './gruenden-mit40/gruenden-mit40.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { PreisfindungComponent } from './preisfindung/preisfindung.component';
import { WirtschaftlichkeitsrechnungComponent } from './wirtschaftlichkeitsrechnung/wirtschaftlichkeitsrechnung.component';

const routes: Routes = [
  {path: 'gruenden-mit-40', component: GruendenMit40Component},
  {path: 'preisfindung', component: PreisfindungComponent},
  {path: 'wirtschaftlichkeitsrechnung', component: WirtschaftlichkeitsrechnungComponent},
  {path: 'impressum', component: ImpressumComponent},
  {path: 'datenschutz', component: DatenschutzComponent},
  {path: '', component: LandingpageComponent},
  {path: '**', component: LandingpageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
