<div class="container">
  <div class="head">
    <img alt="Logo" src="assets/img/Logo.jpg"/>
    <div>
      <p class="p1">Unternehmer</p>
      <p class="p2">Vision</p>
      <a href="tel:02331 7391027">Ihr kürzester Weg: 02331 7391027</a>
      <a href="mailto:hallo@unternehmervision.de">hallo@Unternehmervision.de</a>
    </div>
  </div>
  <a class="buchen" href="https://calendly.com/mk-termin/kontaktgesprach" target="_blank">Termin buchen</a>

  <div class="content">
    <img alt="Beitrag - Gründen mit 40" src="assets/img/GruendenMit40.jpg" routerLink="gruenden-mit-40">
    <img alt="Beitrag - Preisfindung" src="assets/img/Preisfindung.jpg" routerLink="preisfindung">
    <img alt="Wirtschaftlichkeitsrechnung" src="assets/img/Wirtschaftlichkeitsrechnung.png" routerLink="wirtschaftlichkeitsrechnung">
  </div>

  <h1 class="slogan">Wir wünschen Ihnen einen spannenden und erfolgreichen Start in die Selbstständigkeit!</h1>

  <div class="usefulLinks">
    <a href="https://www.arbeitsagentur.de/arbeitslos-arbeit-finden/aktivierungs-vermittlungsgutschein-avgs" target="_blank">AVGS Information der Agentur für Arbeit</a>
    <a href="assets/files/formloser_Antrag_AVGS.pdf" target="_blank">AVGS Antrag für die Agentur für Arbeit</a>
    <a href="https://www.starteffekt.de/" target="_blank">Regionalpartnerschaft mit der StartEffekt GmbH</a>
    <a href="https://calendly.com/mk-termin/kontaktgesprach" target="_blank">Termin online verinbaren <p>Unsere favorisierte Kontaktaufnahme!</p></a>
    <a class="corner" href="tel:023317391027">Rufen Sie mich an: 02331/73 91 027</a>
    <a href="mailto:hallo@unternehmervision.de">E-Mail an: Hallo@UnternehmerVision.de</a>
  </div>
</div>
