<div class="head">
  <img
    alt="Bild von einem lachenden Mann vor Geld"
    src="assets/img/WirtschaftlichkeitsrechnungBig.png"
  />
  <div>
    <h1>Wirtschaftlichkeitsrechnung für kleine Unternehmen</h1>
    <h2>Kennzahlen für den "Chef" auf einem einzigen Din A 4</h2>
    <p>
      Welche Steuerungsparameter (Kennzahlen) führen Sie als Inhaber/
      Geschäftsführer eines kleinen Unternehmens zum Erfolg? Wie erzeugen Sie
      bessere Ergebnisse, eine höhere Rentabilität, mehr Liquidität (oder
      weniger Zahlungsengpässe) bei weiniger Risiko und mehr Freizeit? Ich zeige
      Ihnen ein altbewährtes und doch hochaktuelles System mit Treiberbäumen,
      aus denen Sie sofort Ursache-Wirkungs-Beziehungen ableiten können.
    </p>
    <br />
    <hr />
    <br />
    <h2>Dynamische Berechnung von Projektrenditen</h2>
    <p>
      In der "zweiten Halbzeit" geht es um ein konkretes Beispiel, bei dem wir
      mit dynamischer Berechnung eine Projektrendite oder Projektfinanzierung
      aus einer Mehrjahresperspektive berechnen. 
    </p>
  </div>
</div>

<div class="menue">
  <div id="a">
    <a routerLink="">Ziele</a>
    <a routerLink="../gruenden-mit-40">Gründen auch noch mit 40+ ?</a>
    <a routerLink="../preisfindung">Controlling und Preisfindung mit Excel</a>
  </div>
  <div id="b">
    <button mat-button [matMenuTriggerFor]="menu">
      <mat-icon>menue</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item routerLink="">Ziele</button>
      <button mat-menu-item routerLink="../gruenden-mit-40">
        Gründen auch noch mit 40+ ?
      </button>
      <button mat-menu-item routerLink="../preisfindung">
        Controlling und Preisfindung mit Excel
      </button>
    </mat-menu>
  </div>
</div>

<div class="content">
  <img alt="Bild einer Rakete" src="assets/icons/rocket.svg" />
  <p>
    Es ist vielleicht etwas "komisch" nach dem Ergebnis zu fragen, bevor man
    richtig in der unternehmerischen Tätigkeit drin ist, aber sie wissen ja auch
    wo Sie hinmöchten, bevor Sie ins Auto steigen und losfahren. Der Wechsel der
    Perspektive mit Blick auf das gewünschte Resultat, hilft oft bei der Frage
    mit welchen Ressourcen und mit welcher Methodik das Ziel erreicht werden
    soll. Weniger ist meist mehr!
  </p>
</div>

<div class="content">
  <img alt="Bild von zwei Personen" src="assets/icons/people.svg" />
  <p>
    Ganz individuell oder In kleiner Gruppe starten wir mit einer Beispiel
    Ergebnisrechnung und einer Beispielbilanz. Je nach konkreter Zielsetzung
    besprechen wir die Steuerungsparameter. "Cash is King" erfordert andere
    Stellschrauben als klassische Renditemaximierung.
  </p>
</div>

<div class="content">
  <img alt="Bild einer rennenden Person" src="assets/icons/run.svg" />
  <p>
    Wir lassen Sie nach dem Einstieg nicht allein, aber Sie entscheiden
    punktuell, ob Sie unser Unterstützungsangebot nutzen wollen. Wo genau, zu
    welchem Budget und ggf. mit welcher Förderung.
  </p>
</div>

<div class="usefulLinks">
  <a href="https://calendly.com/mk-termin/kontaktgesprach" target="_blank"
    >Termin online verinbaren</a
  >
  <a class="corner" href="tel:023317391027"
    >Rufen Sie mich an: 02331/73 91 027</a
  >
  <a href="mailto:hallo@unternehmervision.de"
    >E-Mail an: Hallo@UnternehmerVision.de</a
  >
</div>
