import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-gruenden-mit40',
  templateUrl: './gruenden-mit40.component.html',
  styleUrls: ['./gruenden-mit40.component.scss']
})
export class GruendenMit40Component implements OnInit {

  constructor(private titleService:Title) {
    this.titleService.setTitle("Gründen mit 40");
   }

  ngOnInit(): void {
  }

  share(url){
    window.open(url, '_blank');
  }
}
