<div class="container">
    <div class="head">
        <img alt="Logo" src="assets/img/Logo.jpg"  routerLink=""/>
        <div>
          <p class="p1">Unternehmer</p>
          <p class="p2">Vision</p>
        </div>
    </div>

  <h1>Impressum</h1>

  <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
  <p>
    Martin Keller<br />
    UnternehmerVision<br />
    Exterweg 3<br />
    58093 Hagen
  </p>

  <h2>Kontakt</h2>
  <p>
    Telefon: 02331 7391027<br />
    E-Mail: mk@unternehmervision.de
  </p>

  <h2>Umsatzsteuer-ID</h2>
  <p>
    Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
    DE815270306
  </p>

  <h2>Berufshaftpflicht­versicherung</h2>
  <p>
    <b>Name und Sitz des Versicherers:</b> <br />
    HISCOX, Arnulfstr. 31, 80636 München <br />
    <b>Geltungsraum der Versicherung</b> <br />
    Geltungsraum der Versicherung: weltweit ohne USA/Kanada
  </p>

  <h2>Redaktionell Verantwortlicher</h2>
  <p>Dipl.-Kfm. LL.M. corp.restruc. Martin Keller</p>

  <h2>EU-Streitschlichtung</h2>
  <p>
    Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung
    (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a>. Unsere E-Mail-Adresse
    finden Sie oben im Impressum.
  </p>

  <h2>Verbraucherstreit­beilegung/ Universal­schlichtungs­stelle</h2>
  <p>
    Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor
    einer Verbraucherschlichtungsstelle teilzunehmen.
  </p>

  <p>Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a></p>
</div>
