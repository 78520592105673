<div class="head">
  <div class="left">
    <img
      alt="Bild von einem Buch über Preisfindung von Martin Keller"
      src="assets/img/PreisfindungCover.jpg"
    />
    <a
      href="https://www.amazon.de/Preisfindung-unter-verkaufen-agilen-Projektkalkulation/dp/3749479747/ref=sr_1_1?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=Preisfindung+Martin+Keller&qid=1622298869&sr=8-1"
      target="_blank"
      >AMAZON</a
    >
  </div>
  <div class="right">
    <p>
      Die richtige Preisfindung für ein Produkt oder eine Dienstleistung kann
      für kleinere Unternehmen, Selbständige und Gründer existenziell sein.
      Die  Preisfindung ist komplex, weil sie einerseits das Verständnis von
      Zahlen-Daten-Fakten für die Kalkulation voraussetzt und andererseits im
      Endkundenbereich hohes Verhandlungsgeschick erfordert.
    </p>
    <p>
      Im Buch "Preisfindung" werden die Kalkulationsgrundlagen so kurz und knapp
      und so praxisbezogen wie möglich dargestellt. Das Buch enthält fast zu
      jedem Aspekt ein Beispiel und beschreibt die Kalkulation anhand eines
      durchgängigen Beispiels für einen Web Designer von A-Z...
    </p>
    <p>
      Dipl.-Kfm. LL.M. corp. restruc. Martin Keller ist geschäftsführender
      Gesellschafter der FOBEKA GmbH in Hagen. Kerngebiete sind die
      Unternehmensanalyse und -bewertung auf Basis von Jahresabschlüssen,
      Kostenrechnung und Kennziffern.
    </p>
    <br />
    <hr />
    <br />
    <h1>
      Bei einer Beratung für Unternehmen ist eine BAFA Förderung möglich, für
      Arbeitssuchende und für von Arbeitslosigkeit Gefährdete zahlt die Agentur
      für Arbeit. 
    </h1>
    <a href="mailto:hallo@unternehmervision.de"
      >Kontaktieren Sie mich per E-Mail und informieren Sie sich
      unverbindlich!</a
    >
  </div>
</div>

<div class="menue">
  <div id="a">
    <a routerLink="">Ziele</a>
    <a routerLink="../gruenden-mit-40">Gründen auch noch mit 40+ ?</a>
    <a routerLink="../wirtschaftlichkeitsrechnung">Controlling für KMU</a>
  </div>
  <div id="b">
    <button mat-button [matMenuTriggerFor]="menu">
      <mat-icon>menue</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item routerLink="">Ziele</button>
      <button mat-menu-item routerLink="../gruenden-mit-40">
        Gründen auch noch mit 40+ ?
      </button>
      <button mat-menu-item routerLink="../wirtschaftlichkeitsrechnung">
        Controlling für KMU
      </button>
    </mat-menu>
  </div>
</div>

<div class="content">
  <img alt="Bild einer Rakete" src="assets/icons/rocket.svg" />
  <p>
    Als <b>Unternehmer</b> eines kleineren Unternehmens oder als
    <b>Solopreneur</b>
    unterscheiden Sie sich in positivem Sinne nicht wirklich von einem
    <b>"Gründer"</b>. Sie suchen ständig nach neuen Chancen für Ihr Unternehmen
    und erleben jeden Tag, wie Sie mit Teilprojekten scheitern oder diese
    zumindest verbessern müssen. In etablierten Unternehmen nennt sich das
    Change Management, wenn es kritischer wird, kann zum Turnaround Management
    werden - aber sicher ist: Es ist jedes Mal eine Neuorientierung / ein
    Neustart des Geschäftsmodells mit dem Ziel zu wachsen.
  </p>
</div>

<div class="content">
  <img alt="Bild von zwei Personen" src="assets/icons/people.svg" />
  <p>
    Als Anfänger, aber auch als erfahrener Fallschirmspringer wissen Sie, dass
    es sich im Tandemsprung sicherer fliegt. Gründungen im Team sind wesentlich
    erfolgreicher als Einzelgründungen - wenn Sie aber bei der Gründung oder mit
    Ihrer Entscheidung, in welche Richtung Sie weitergehen sollen, auf sich
    alleine gestellt sind, hilft ein <b>Sparringspartner</b> auf Augenhöhe ganz
    ungemein dabei, den richtigen Weg zu finden. 
  </p>
</div>

<div class="content">
  <img alt="Bild einer rennenden Person" src="assets/icons/run.svg" />
  <p>
    Als Vorgründungsberatung oder nach der Gründung mit einer BAFA Beratung sind
    50% - 80% oder bei Unternehmen mit negativen Ergebnissen bzw. negativem
    Eigenkapital sogar 90% <b>Förderung</b> möglich. <br /><br />
    Sie wollen <b>investieren</b>? Wissen nicht, ob Sie gründen sollen, lieber
    ein Franchise Modell oder doch lieber ein Unternehmen kaufen? Wenn Sie in
    NRW in bestimmten Regionen investieren und gleichzeitig Mitarbeiter
    einstellen gibt es die Möglichkeit,
    <b>nicht rückzahlbare Zuschüsse vom Staat</b> zu erhalten.
  </p>
</div>

<div class="usefulLinks">
  <a href="https://calendly.com/mk-termin/kontaktgesprach" target="_blank"
    >Termin online verinbaren</a
  >
  <a class="corner" href="tel:023317391027"
    >Rufen Sie mich an: 02331/73 91 027</a
  >
  <a href="mailto:hallo@unternehmervision.de"
    >E-Mail an: Hallo@UnternehmerVision.de</a
  >
</div>

<p class="fazit">
  Nehmen Sie für ein unverbindliches Gespräch direkt Kontakt zu mir auf - Egal
  ob Sie eine detaillierte Beratung oder nur ein paar Tipps wollen - Sie können
  bei einem Kontakt nur gewinnen!
</p>
