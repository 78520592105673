<div class="head">
    <img alt="Bild von einem lachenden älteren Mann" src="assets/img/GruendenMit40Big.jpg">
    <div>
        <p>Sie waren viele Jahre im Job als Spezialist erfolgreich?

            Plötzlich beim Gesinnungsabgleich durchgefallen?
            Sie sind k e i n Einzelfall, der sich etwas vorzuwerfen hätte!

            Sie haben Ecken und Kanten und eine eigene Meinung, weil Sie wissen, wie es geht, aber Sie haben auch
            Ihren
            Preis und sind vergleichsweise "teuer"?

            Zudem sind Sie vielleicht nicht mehr bereit, Überstunden ohne Ende zu machen, nur weil ein "Chef" das so
            möchte?
        </p>
        <br>
        <hr>
        <br>
        <h1>Werden Sie ihr eigener Boss!</h1>
        <p>
            Es ist nicht immer einfach, aber es gibt kostenfreie Hilfe für Sie:
        </p>
        <p>
            Wenn das "Amt" Ihren Wunsch nach Selbständigkeit unterstützt, weil Sie von Arbeitslosigkeit bedroht sind
            oder arbeitslos sind, können Sie mit einem AVGS Gutschein individuelle Einzelcoachings bis zu 120
            Stunden
            zur Vorbereitung Ihrer Selbständigkeit erhalten - die Kosten werden komplett übernommen.
        </p>
        <a href="mailto:hallo@unternehmervision.de">Kontaktieren Sie mich per E-Mail und
            informieren Sie sich
            unverbindlich!</a>
    </div>
</div>

<div class="menue">
    <div id="a">
        <a routerLink="">Ziele</a>
        <a routerLink="../preisfindung">Controlling und Preisfindung mit Excel</a>
        <a routerLink="../wirtschaftlichkeitsrechnung">Controlling für KMU</a>
    </div>
    <div id="b">
        <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>menue</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item routerLink="">Ziele</button>
            <button mat-menu-item routerLink="../preisfindung">Controlling und Preisfindung mit Excel</button>
            <button mat-menu-item routerLink="../wirtschaftlichkeitsrechnung">Controlling für KMU</button>
        </mat-menu>
    </div>
</div>

<div class="content">
    <img alt="Bild einer Rakete" src="assets/icons/rocket.svg">
    <p>Als Gründungsberater, Mentor und Business-Plan-Gutachter helfe ich überwiegend jungen Menschen in Zusammenarbeit
        mit den jeweiligen Wirtschaftsförderungen nicht nur in Dortmund und Bochum beim Start in die Selbständigkeit.
        Rufen Sie einfach an, wenn Sie eine Frage haben, die wir für Sie klären können. Ich gebe Ihnen meine persönliche
        Meinung und meine Erfahrungen zu Rechtsformen, Gewerbeeintrag, Steueranmeldung, Buchhaltung, Fallstricken und
        Chancen. Sie erhalten Tipps bzw. aktive Unterstützung zu Ihrem Businessplan und Informationen über staatliche
        Förderungen.</p>
</div>

<div class="content">
    <img alt="Bild von zwei Personen" src="assets/icons/people.svg">
    <p>Im Einzel-Coaching helfe ich Ihnen wenn Sie von Arbeitslosigkeit bedroht sind (befristeter Vertrag oder Kündigung
        erhalten).
        Als Regionalpartner eines AZAV-zertifizierten Bildungsträgers bringe ich Sie erfolgreich in die Selbständigkeit
        - und das Beste: Für Sie ist das mit bis zu 120 Trainingseinheiten vollkommen kostenfrei. </p>
</div>

<div class="content">
    <img alt="Bild einer rennenden Person" src="assets/icons/run.svg">
    <p>Wenn der AVGS Gutschein nicht möglich ist, kann ich Ihnen vor der Gründung als zugelassener BPW- Berater in NRW
        einen 50% Zuschuss des Landes NRW anbieten.
        <br><br>
        Die Rechnung ist einfach:
        Welchen ALG I Anspruch erhalten Sie mit einem plausiblen und durchgerechneten Businessplan und wieviel kostet
        die Beratung?
    </p>
</div>

<div class="usefulLinks">
    <a href="https://www.arbeitsagentur.de/arbeitslos-arbeit-finden/aktivierungs-vermittlungsgutschein-avgs"
        target="_blank">AVGS Information der Agentur für Arbeit</a>
    <a href="assets/files/formloser_Antrag_AVGS.pdf" target="_blank">AVGS
        Antrag für die Agentur für Arbeit</a>
    <p>Ich bin mir bewusst, dass die Kosten der entscheidende Faktor für eine Inanspruchnahme von
        Unterstützungsleistungen sind; daher habe ich vorstehend die 50% Förderung, aber auch das kostenfreie Angebot
        mit AVGS Gutschein vorgestellt. Das Beste ist, bei mir zahlen Sie bei der Gründungsberatung oder kaufmännischen
        Schulungen inkl. Excel keine Mehrwertsteuer on top.</p>
    <p>Selbstverständlich kann ich nur jedem Gründer empfehlen mit "Flugbegleitung zu starten" - Gründungen mit Beratung
        und Gründungen im Team sind nachweislich erfolgreicher. Eine Beratung kann aber nur eine Unterstützung bei Ihren
        Bemühungen sein; Sie sind die treibende Kraft und Sie haben den starken Willen Verantwortung zu übernehmen und
        "Ihr Ding" voranzutreiben - ohne Ihre Motivation lohnt sich eine Beratung bei mir nicht.</p>
    <p>Unser Beratungsangebot an Sie ist können Sie nach Terminvereinbarung in unserem Büro in der Birkenstraße 1 in
        Hagen in Anspruch nehmen oder wir unterstützen Sie online, so dass Sie zu einer Ihnen passenden Zeit ganz bequem
        vom Sofa aus gründen können.</p>
    <a href="https://calendly.com/mk-termin/kontaktgesprach" target="_blank">Termin online verinbaren <p>Unsere
            favorisierte Kontaktaufnahme!</p></a>
    <a class="corner" href="tel:023317391027">Rufen Sie mich an: 02331/73 91 027</a>
    <a href="mailto:hallo@unternehmervision.de">E-Mail an: Hallo@UnternehmerVision.de</a>
</div>

<div class="fazit">
    <h2>Was macht Gründer mit 40 + aus und warum können Sie erfolgreicher sein als jüngere Unternehmensgründer?</h2>
    <p>
        Ist es das "Plus" an Fachwissen oder die besonnenere Persönlichkeit?
    </p>
    <p>Welche persönlichen, fachlichen und finanziellen Voraussetzungen braucht man eigentlich für eine erfolgreiche Gründung? Was ist das überhaupt: Eine erfolgreiche Gründung?</p>
    <p>Wie geht man eine Unternehmensgründung an? Wie sind die ersten Schritte?</p>
    <ul>
        <li>Schafft eine GmbH Sicherheit?</li>
        <li>Wie läuft es steuerlich? (Erfahrungsaustausch, keine Rechts- oder Steuerberatung)</li>
        <li>Welche finanziellen Voraussetzungen sollten gegeben sein?</li>
        <li>An welchen Fallstricken verfangen sich die meisten?</li>
        <li>Sie können nicht verkaufen? Sie werden überrascht sein.</li>
        <li>Wie soll ich meinen Businessplan aufbauen?</li>
    </ul>
</div>

<!-- <div class="shareButtons">
    <button mat-raised-button id="twitter" (click)="share('https://twitter.com/share?url=https%3A%2F%2Funternehmervision.de%2Fgruenden-mit-40%2F&text=Gr%C3%BCnden%20auch%20noch%20mit%2040%20%2B%20%3F')"><img src="assets/icons/Twitter.svg" alt="Twitter Logo">twittern</button>
    <button mat-raised-button id="facebook" (click)="share('https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Funternehmervision.de%2Fgruenden-mit-40%2F')"><img src="assets/icons/Facebook.png" alt="Facebook Logo">teilen</button>
    <button mat-raised-button id="linkedIn" (click)="share('https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Funternehmervision.de%2Fgruenden-mit-40%2F&title=Gr%C3%BCnden%20auch%20noch%20mit%2040%20%2B%20%3F')"><img src="assets/icons/LinkedIn.png" alt="LinkedIn Logo"></button>
</div> -->