import { Component, OnInit } from '@angular/core';
import {Title, Meta} from "@angular/platform-browser";

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss']
})
export class LandingpageComponent implements OnInit {

  constructor(private titleService:Title, private meta: Meta) {
    this.titleService.setTitle("Ziele - Unternehmervision");

    
    this.meta.addTags([
      // { name: 'description', content: 'This is an article about Angular Meta service' },
      { name: 'keywords', content: 'unternehmervision,unternehmen,vision,gruenden,preisfindung' }  
    ]);  

   }

  ngOnInit(): void {
  }

}
